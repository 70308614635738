import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { apiBase } from './apiBase';
import authMiddleware from './authentication/auth.Middleware';
import authreducer from './authentication/authSlice';

export const store = configureStore({
  reducer: {
    [apiBase.reducerPath]: apiBase.reducer,
    keyclockauth:authreducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(thunk,apiBase.middleware,authMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
