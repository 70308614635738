import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { apiBase } from "./app/apiBase";
import authThunk from "./app/authentication/authThunk";
import { AppDispatch } from "./app/store";
import RoutesConfig from "./support/RouteConfig";
import { currentUser } from "./app/authentication/authSlice";



function App() {

  const dispatch = useDispatch<AppDispatch>();
  const auth = useAuth();
  const user = useSelector(currentUser);

  useEffect(() => {
    if (auth) {  // Check if auth is available
      dispatch(authThunk(auth));
    }
  }, [dispatch, auth]);  // Add `auth` to dependency array

  return (
    <>
      {user.isAuthenticated ? <>
        <RouterProvider router={RoutesConfig} />
        <ApiProvider api={apiBase}>
          <div className="App"></div>
        </ApiProvider></>
        :
        <p>Waiting for authentication...</p>
      }
    </>
  );
}

export default App;
