import { Middleware } from "@reduxjs/toolkit";
import { UserManager } from "oidc-react";
import { clearCredentials, setCredentials } from "./authSlice";

const authMiddleware: Middleware = (store) => (next) => (action) => {
  if (setCredentials.match(action)) {
    // Handle token setting logic if needed
    if (action.payload.name)
      console.log(`User ${action.payload.name} has logged in.`);
  }

  if (clearCredentials.match(action)) {
    // Trigger OIDC logout or token cleanup
    const userManager = new UserManager({
      authority: process.env.REACT_APP_OIDC_AUTHORITY!,
      client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
      redirect_uri: process.env.REACT_APP_OIDC_CLIENT_ID!,
    });
    userManager.signoutRedirect();
  }

  return next(action);
};

export default authMiddleware;
