import { Route, createBrowserRouter, createRoutesFromElements, } from "react-router-dom";
import Layout from "../features/Layout.ui";
import Dashboard from "../features/Dashboard/Dashboard.ui";
import SecuredRoute from "./SecuredRoute";

const RouteConfig = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />} >
        <Route index element={<SecuredRoute element={<Dashboard />} isAuthenticated={true} />} />
      </Route>
    </>
  )
);

export default RouteConfig;
