import React from 'react';
import { Navigate } from 'react-router-dom';

type SecuredRouteProps = {
  element: React.ReactElement;
  isAuthenticated: boolean;
};

const SecuredRoute: React.FC<SecuredRouteProps> = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default SecuredRoute;
