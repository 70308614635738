import { UserManager } from "oidc-react";
import { useDispatch } from "react-redux";
import { clearCredentials } from "./authSlice";

const useLogout = () => {
  const dispatch = useDispatch();
  
  const userManager = new UserManager({
    authority: process.env.REACT_APP_OIDC_AUTHORITY!,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
    client_secret: process.env.REACT_APP_OIDC_CLIENT_SECRET!,
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI!,
    post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI!,
    silent_redirect_uri:process.env.REACT_APP_OIDC_REDIRECT_URI,
  });

  const handleLogout = async () => {
    const user = await userManager.getUser();

    userManager.removeUser();
    dispatch(clearCredentials());
    userManager.signoutRedirect({
        id_token_hint: user?.id_token,
      });
  };

  return { handleLogout };
};

export default useLogout;