import { AuthProvider, AuthProviderProps } from 'oidc-react'
import App from '../../App'


const oidcConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY!,
    clientId: process.env.REACT_APP_OIDC_CLIENT_ID!,
    clientSecret: process.env.REACT_APP_OIDC_CLIENT_SECRET!,
    responseType: 'code',
    scope: 'openid profile email',
    redirectUri: process.env.REACT_APP_OIDC_REDIRECT_URI!,
    onSignIn: (user) => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
};

function AuthWarper() {

    return (
        <AuthProvider {...oidcConfig}>
            <App />
        </AuthProvider>
    )
}

export default AuthWarper