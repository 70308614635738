import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthContextProps } from "oidc-react";
import { setCredentials } from "./authSlice";

const authThunk = createAsyncThunk(
  "auth/login",
  async (auth: AuthContextProps, thunkAPI) => {
    if (auth) {
      thunkAPI.dispatch(
        setCredentials({
          userId: auth.userData?.profile.sub ?? null,
          name: auth.userData?.profile.name ?? null,
          isAuthenticated: auth.userData?.profile.sub ? true : false,
          email: auth.userData?.profile.email ?? null,
          isEmailVerified: auth.userData?.profile.email_verified ?? false,
          phone: auth.userData?.profile.phone_number ?? null,
          isPhoneVerified:
            auth.userData?.profile.phone_number_verified ?? false,
          token: auth.userData?.access_token ?? null,
        })
      );
    }
  }
);

export default authThunk;
