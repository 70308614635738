export class ApiNavigation {

   //#region  Api's Base Urls

   static readonly API_BASE_URL: string = 'https://localhost:56000/'

   //#endregion

   //#region  External APi
   static readonly EXTERNAL_API_PREFIX = 'api/externalapi/';
   static readonly GET_DIAL_CODE: string = ApiNavigation.EXTERNAL_API_PREFIX + 'getdialcodes';

   //#endregion

   //#region Auth

   static readonly USER_API_PREFIX = 'api/user/v1.0/';
   static readonly USER_CREATE: string = ApiNavigation.USER_API_PREFIX + 'create';
   static readonly USER_SIGNIN: string = ApiNavigation.USER_API_PREFIX + 'auth/login'
   static readonly USER_FORGETPASSWORD_SENDCODE: string = ApiNavigation.USER_API_PREFIX + 'auth/forgetpassword/codeto/email/'
   static readonly USER_FORGETPASSWORD_VERIFYCODE: string = ApiNavigation.USER_API_PREFIX + 'auth/forgetpassword/verify/email/'

   //#endregion
}