import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiNavigation } from "../support/ApiNavigation";
import { RootState } from "./store";

const baseQuery = fetchBaseQuery({
  baseUrl: ApiNavigation.API_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).keyclockauth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiBase = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ['DialCodes', 'User'],
  endpoints: builder => ({}),
});
