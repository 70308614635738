import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAurhServerUser } from "./IAurhServerUser";
import { stat } from "fs";

// Initial state for authentication
const initialState: IAurhServerUser = {
  userId: null,
  name: null,
  isAuthenticated: false,
  email: null,
  isEmailVerified: false,
  phone: null,
  isPhoneVerified: false,
  token: null,
};

// Create a slice to manage authentication state
const authSlice = createSlice({
  name: "keyclockauth",
  initialState,
  reducers: {
    // Action to set credentials after successful authentication or token refresh
    setCredentials: (state: IAurhServerUser, action: PayloadAction<IAurhServerUser>) => {
      return { ...state, ...action.payload };
    },
    // Action to clear credentials (e.g., on logout or token expiration)
    clearCredentials: (state) => {
      return initialState;
    },
  },
});

// Export actions to be dispatched from components
export const { setCredentials, clearCredentials } = authSlice.actions;
export const currentUser = (state: { keyclockauth: IAurhServerUser }) => state.keyclockauth;
// Export the reducer to be included in the store
export default authSlice.reducer;
