import { useAuth } from 'oidc-react';
import { useSelector } from 'react-redux';
import { currentUser } from '../../app/authentication/authSlice';
import useLogout from '../../app/authentication/logout';

function Dashboard() {
  const logout = useLogout();
  const auth = useAuth();
  const user = useSelector(currentUser);


  return <>
    <div>Dashboard.ui
      <p>{user.userId ?? ""}</p>
      <p>{auth.userData?.profile.sub ?? ""}</p>
      <p>{auth.userData?.profile.email}</p>
      <p>{auth.userData?.profile.name}</p>
      <p>{auth.userData?.token_type}</p>
      <p>{(auth.userData?.expires_in! / 60).toString()}</p>
      <p>{new Date(auth.userData?.expires_at! * 1000).toString()}</p>
      <p>{auth.userData?.access_token}</p>
      <p>{auth.userData?.refresh_token}</p>
      <p>{process.env.REACT_APP_OIDC_REDIRECT_URI}</p>

      <input type='button' value='LogOut' onClick={logout.handleLogout} />
    </div>
  </>;
}

export default Dashboard;
